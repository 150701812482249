
import React from 'react'
import styled, { keyframes } from "styled-components"
import Codepen from './icons/codepen'
import Github from './icons/github'
import Linkedin from './icons/linkedin'

const Social = styled.div`
	margin-top: 2rem;
	a {
		margin-right: 1rem;
		&:last-child {
			margin: 0;
		}
		&:hover {
			svg {
				fill: #FFF;
			}
		}
	}
	svg {
		transition: .25s linear;
		fill: #CED7E1;
	}
`

const wavesAnimation = keyframes`
	0% {
		transform: translate(-50%, -75%) rotate(0deg) 
	}
	100% {
		transform: translate(-50%, -75%) rotate(360deg)
	}
`

const Waves = styled.div`
	position: absolute;
	bottom: -1px;
	left: 0;
	right: 0;
	background: #CED7E1;
	transition: 500ms;
	overflow: hidden;
	height: 100px;
	@media (min-width: 620px) {
		height: 200px;
	}
	@media (min-width: 1024px) {
		height: calc(100vw / 5);
	}
	&:before, &:after {
		content: '';
		position: absolute;
		width: 300vw;
		height: 300vw;
		top: -65vw;
		left: 50%;
		border-radius: 44%;
		transform: translate(-50%, -75%);
	}
	&:before {
		background: rgba(18, 21, 62, 1);
		animation: ${wavesAnimation} 16s linear infinite;
	}
	&:after {
		background: rgba(18, 21, 62, 0.4);
		animation: ${wavesAnimation} 30s linear infinite;
	}
`

const Hero = styled.section`
	background: #12153e;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	position: relative;
	height: 100vh;
	@media (min-width: 620px) {
		height: 90vh;
	}
	.header__title {
		text-align: center;
		margin-top: -200px;
		z-index: 1;
		h1 {
			font-size: 2.8rem;
			font-weight: 500;
			color: #CED7E1;
			margin-bottom: 1.2rem;
		}
		h2 {
			font-weight: 400;
			font-size: 1.2rem;
			color: #CED7E1;
		}
	}
`

const Header = () => {
    
  return (
    <Hero>
		<div className="header__title">
			<h1>Andy Bridges</h1>
			<h2>Software Engineer</h2>
			<Social>
				<a href="https://codepen.io/andymbridges" aria-label="Codepen" target="_blank" rel="noreferrer">
					<Codepen />
				</a>
				<a href="https://github.com/andymbridges" aria-label="Github" target="_blank" rel="noreferrer">
					<Github />
				</a>
				<a href="https://www.linkedin.com/in/andymbridges" aria-label="Linkedin" target="_blank" rel="noreferrer">
					<Linkedin />
				</a>
			</Social>
		</div>
		<Waves />
    </Hero>
  )
}

export default Header