import React from "react"
import styled from "styled-components"

const Introduction = styled.section`
    color: #000;
    width: 100%;
    background: #CED7E1;
    padding: 1rem 0;
    @media (min-width: 1024px) {
        padding: 3rem 0;
    }
    article {
        max-width: 800px;
        margin: 2.4rem auto 3.4rem;
        padding: 0 1rem;
        @media (min-width: 1024px) {
            margin: 2.4rem auto;
        }
        p {
            font-size: 1rem;
            line-height: 1.6;
            @media (min-width: 1024px) {
                font-size: 1.1rem;
            }
        } 
    }
`

const Intro = () => {

  return (
    <Introduction>
        <article>
            <p>Andy Bridges is an experienced Software Engineer and Technology Leader with over 12 years of industry exposure. Portfolio includes Google, Facebook, Mercedes-Benz, NowTV, Royal Air Force and O2 Academy. </p>
        </article>
    </Introduction>
  )
}

export default Intro